<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        การเติมน้ำมัน
      </v-toolbar-title>
    </v-toolbar>

    <div class="mx-4 box box-2" style="min-height: 100%;">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            style="height: 100%"
        >
          <v-card
              style="height: 100%; overflow: auto"
              class="pa-2 rounded-lg elevation-2"
              outlined
              tile
          >
            <v-data-table :items="trips" :headers="headers" :header-props="{ sortIcon: null }">
              <template v-slot:item.type="{item}">
                <router-link v-if="item.order !== null" :to="`/order/${item.order.id}`">ส่งของ (Order
                  {{ String(item.order.id).padStart(4, '0') }})
                </router-link>
                <router-link v-if="item.po_item !== null" :to="`/stock/po/${item.po_item.id}`">รับสินค้า (PO
                  {{ item.po_item.id }})
                </router-link>
              </template>
              <template v-slot:item.driver="{item}">
                {{ item.driver.first_name }} {{ item.driver.last_name }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "OilTrackerByOilList",
  data: () => ({
    oil: {},
    trips: [],
    headers: [
      {
        text: 'วันที่',
        value: 'date',
      },
      {
        text: 'ชื่อรถ',
        value: 'vehicle.name'
      },
      {
        text: 'ทะเบียน',
        value: 'vehicle.licence',
        align: 'center'
      },
      {
        text: 'ประเภท',
        value: 'type',
        align: 'center'
      },
      {
        text: 'ระยะทาง',
        value: 'distance',
        align: 'center'
      },
      {
        text: 'คนขับ',
        value: 'driver',
        align: 'center'
      },


    ]
  }),
  created() {
    this.getOilData()
  },
  methods: {
    getOilData() {
      axios.get(`/api/tracks/oil/${this.$route.params.id}/`)
          .then(res => {
            this.oil = res.data.data
            this.trips = res.data.data.trips
            console.log(this.trips)
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
